<template>
  <v-card class="mb-5">
    <v-tabs-items v-model="selectedMenu">
      <v-tab-item value="user">
        <v-col cols="12" class="py-0">
          <v-row align="center" justify="space-around" no-gutters>
            <div class="my-3 text-center font-16 text-black txt">
              <span>{{ $t("form.status") }}:</span> <b>{{ $t(`auth.${user.type}`) }}</b>
            </div>
            <div class="my-3 text-center font-16 text-black txt " v-if="permissions.client">
              <span>{{ $t("profile.payment_plan") }}:</span>
              <b> {{ userPlanInfo.name || "Не вибрано" }}</b>
            </div>
            <div class="my-3 text-center font-16 text-black txt" v-if="permissions.client">
              <span>{{ $t("profile.deport") }}:</span> <b>{{ deport.name || "Не вибрано" }}</b>
            </div>
          </v-row>
        </v-col>
        <v-card-text>
          <v-row :class=" $vuetify.breakpoint.xs ? 'justify-center' : ''">
            <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'col-12' : ''">
              <v-row>
                <profile-avatar :user="user" :changePhotoPreview="changePhotoPreview" :resetPhoto="resetPhoto"/>
              </v-row>
            </v-col>
            <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'col-12' : 'py-5 px-5'">
              <v-row>
                <v-col cols="6" :class="$vuetify.breakpoint.xs ? 'col-12' : ''">
                  <VTextField
                    :label="$t('form.name')"
                    dense
                    color="success"
                    outlined
                    v-model="user.name"
                    :error-messages="nameErrors"
                    class="br-10"
                    v-on:keypress="validationForName($event)"
                    @paste="validationForName($event)"
                    :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                    @input="noEngText($event, false)"
                  />
                </v-col>
                <v-col cols="6" :class="$vuetify.breakpoint.xs ? 'col-12' : ''">
                  <VTextField
                    :label="$t('form.surname')"
                    dense
                    outlined
                    color="success"
                    v-model="user.surname"
                    :error-messages="surnameErrors"
                    @paste="validationForSurname($event)"
                    class="br-10"
                    v-on:keypress="validationForSurname($event)"
                    :hint="onEngText ? '' : $t('form.hint.SurnameNoEngText')"
                    @input="noEngText($event, false)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" :class="$vuetify.breakpoint.xs ? 'col-12' : ''">
                  <VTextField
                    class="inputPhone br-10"
                    :label="$t('form.phone_number')"
                    dense
                    color="success"
                    hide-details="auto"
                    outlined
                    prefix="+38"
                    v-on:keypress="validationForPhoneNumber($event)"
                    @paste="validationForPhoneNumber($event)"
                    v-model="user.phone"
                    :maxLength="20"
                    :error-messages="phoneErrors"
                  >
                  </VTextField>
                </v-col>
                <v-col cols="6" v-if="permissions.client" :class="$vuetify.breakpoint.xs ? 'col-12' : ''">
                  <VTextField
                    :label="$t('form.company')"
                    dense
                    outlined
                    hide-details="auto"
                    v-model="user.company"
                    color="green"
                    class="br-10"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="api_keys">
        <v-card-text>
          <v-col cols="12" class="py-0 my-3 br-10" v-if="permissions.can_see_api_keys_menu">
            <VTextField
              class="my-0 py-0 d-flex align-items-center br-10"
              dense
              :disabled="true"
              outlined
              label="NewSend Api Key"
              v-model="user.uuid_crm_apikey"
              color="green"
              left
              icon="false"
              id="custom-disabled"
            >
              <template slot="append" v-if="user.uuid_crm_apikey">
                <VBtn icon class="mb-1 py-0">
                  <v-icon @click="copyToCLipboard" class="icon-btn mb-0 py-0"
                    >mdi-content-copy</v-icon
                  >
                </VBtn>
              </template>
            </VTextField>
          </v-col>

          <v-col cols="12" class="pb-5 " v-if="permissions.client">
            <div class="d-flex align-center">
              <!-- <VTextField
                dense
                outlined
                aria-autocomplete="off"
                v-model="user.np_apikey"
                label="NP Api Key"
                color="green"
                class="br-10"
                hide-details=""
                :disabled="user.is_np_apikey ===  '0'"
              /> -->
              <v-combobox
                dense
                class="br-10"
                outlined
                label="NP Api key"
                :item-text="'np_apikey'"
                :menu-props="{ bottom: true, offsetY: true }"
                :item-value="'np_apikey'"
                v-model="user.np_apikey"
                :disabled="user.is_np_apikey === '0'"
                hide-details=""
                @change="counterpartyList()"
                :items="np_apikeys"
                color="success"
              >
              </v-combobox>
              <v-switch
                :false-value="'0'"
                :true-value="'1'"
                color="success"
                hide-details
                v-model="user.is_np_apikey"
              />
            </div>
            <div class="listCounterparty" v-if="user.is_np_apikey === '1'">
              <v-radio-group v-model="listCounterpartyDefaultItem" hide-details="">
                <div

                  class="listCounterparty__item"
                  :style="$vuetify.breakpoint.xs ? 'flex-wrap: wrap; justify-content: center': ''"
                  v-for="(item, index) in listCounterparty"
                  :key="index"
                >
                  <span :style="$vuetify.breakpoint.xs ? 'text-align: center; width: 100%; max-width: 100%; display: block;' : ''">
                    {{ item.name }}
                  </span>
                  <div class="listCounterparty__item-buttons"  >
                    <v-radio
                      :key="index"
                      :value="index"
                      class="mb-0"
                      @change="changeRadioCounterparty(item.uuid)"
                      color="success"
                    ></v-radio>
                    <v-icon @click="deleteCounterparty(item)">mdi-trash-can-outline</v-icon>
                    <v-icon @click="editCounterparty(item)">mdi-pencil</v-icon>
                  </div>
                </div>
                <div class="listCounterparty__item pt-2">
                  <VBtn @click="npDialog.visible = true" class="success-bg text-white br-10 w-100">
                    {{ $t("btn.add") }}
                  </VBtn>
                </div>
              </v-radio-group>
            </div>
          </v-col>

          <v-col cols="12" class="pb-5 " v-if="permissions.can_see_sms_integrations">
            <div class="d-flex align-center">
              <VTextField
                dense
                outlined
                aria-autocomplete="off"
                v-model="user.turbosms_apikey"
                label="Turbo SMS Api Key"
                color="green"
                class="br-10"
                hide-details=""
                :disabled="user.is_turbosms_active == '0'"
              />
              <v-switch
                :false-value="'0'"
                :true-value="'1'"
                color="success"
                hide-details
                v-model="user.is_turbosms_active"
              />
            </div>
            <div class="listCounterparty pa-2" v-if="user.is_turbosms_active === '1'">
              <VTextField
                dense
                outlined
                aria-autocomplete="off"
                v-model="user.turbosms_sender"
                label="Sender Name"
                color="green"
                class="br-10"
                hide-details=""
              />
            </div>
          </v-col>
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="password">
        <v-card-text>
          <!-- <v-col cols="12" class="px-0 py-5 mt-5" style="padding:0 11px!important" v-if="!showPass">
          <VBtn class="py-5 br-10" color="grey darken-1" outlined block @click="showPass = true">
            <v-icon left> mdi-lock</v-icon>
            {{ $t("profile.change_pass") }}
          </VBtn>
        </v-col> -->
          <v-row cols="12" class="px-0 py-5 mt-5">
            <v-col cols="6" :class="$vuetify.breakpoint.xs ? 'col-12' : ''">
              <VTextField
                outlined
                class="iconPass br-10"
                :label="$t('profile.current_pass')"
                dense
                color="success"
                v-model="passForm.currentPass"
                :type="currentPasswordVisible ? 'text' : 'password'"
                :append-icon="currentPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="currentPasswordVisible = !currentPasswordVisible"
                :error-messages="passwordErrors"
                v-on:keypress="validationForPassword($event)"
                @paste="validationForPassword($event)"
              />
            </v-col>
            <v-col cols="6" :class="$vuetify.breakpoint.xs ? 'col-12' : ''">
              <VTextField
                outlined
                class="iconPass br-10"
                :label="$t('profile.new_pass')"
                dense
                color="success"
                v-model="passForm.newPass"
                :type="newPasswordVisible ? 'text' : 'password'"
                :append-icon="newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="newPasswordVisible = !newPasswordVisible"
                :error-messages="newPassErrors"
                v-on:keypress="validationForPassword($event)"
                @paste="validationForPassword($event)"
              />
            </v-col>
            <v-col cols="12">
              <VTextField
                outlined
                class="iconPass br-10"
                :label="$t('profile.сonfirm_pass')"
                dense
                color="success"
                v-model="passForm.newPassRepeat"
                :type="repeatPasswordVisible ? 'text' : 'password'"
                :append-icon="repeatPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="repeatPasswordVisible = !repeatPasswordVisible"
                :error-messages="newPassRepeatErrors"
                v-on:keypress="validationForPassword($event)"
                @paste="validationForPassword($event)"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="interface">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <span class="font-weight-bold">{{ $t("theme.theme") }}</span>
              <v-row>
                <v-col cols="3" sm="4" :class="$vuetify.breakpoint.xs ? 'col-12' : ''" v-for="(theme, index) in Object.values(THEMES)" :key="index">
                  <v-card
                    @click="onChangeSelectedThemeHandler(theme.text)"
                    :color="theme.text == getChosenTheme ? 'green darken-1' : ''"
                  >
                    <v-card-text>
                      <v-row justify="space-between">
                        <v-col cols="auto">
                          <span
                          :class="{ 'white--text': theme.text === getChosenTheme}"
                            style="text-transform: capitalize;"
                            >{{ $t(`theme.${theme.text}`) }}</span
                          ></v-col
                        >
                        <v-col cols="auto"
                          ><v-icon :color="theme.text == getChosenTheme ? 'white' : ''">{{
                            theme.icon
                          }}</v-icon></v-col
                        >
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="12" sm="8" lg="8">
              <span class="font-weight-bold">{{ $t("theme.language") }}</span>
              <v-row>
                <v-col cols="3" sm="4" :class="$vuetify.breakpoint.xs ? 'col-12' : ''"
                       v-for="(item, index) in languages" :key="index">
                  <v-card
                    @click="switchLanguage(item)"
                    :color="currentLanguage.translationKey === item.translationKey ? 'green darken-1' : ''"
                  >
                    <v-card-text>
                      <v-row justify="space-between">
                        <v-col cols="auto" class="d-flex align-center justify-center pa-2">
                              <span
                                :class="currentLanguage.translationKey === item.translationKey ? 'white--text' : ''"
                                style="text-transform: capitalize;"
                              >{{ item.title }}</span
                              ></v-col
                        >
                        <v-col cols="auto"
                               class="d-flex align-center justify-center pa-2"
                        >
                              <span>
                                  <country-flag v-if="item.flagCode" :country="item.flagCode"
                                                />
                               <span v-else class="flag__wrapper">
                                  <img class="no-flag"
                                       src="https://flagicons.lipis.dev/flags/4x3/xx.svg"/>
                               </span>
                              </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col>
              <span class="font-weight-bold">{{ $t("theme.table_size") }}</span>
              <v-row>
                <v-col cols="6" :class="$vuetify.breakpoint.xs ? 'col-12' : ''">
                  <v-row>
                    <v-col cols="6" :class="$vuetify.breakpoint.xs ? 'col-6' : ''" v-for="(item, index) in Object.values(SIZES)" :key="index">
                      <v-card
                        @click="onChangeSelecteSize(item)"
                        :color="isSameSize(item.text) ? 'green darken-1' : ''"
                      >
                        <v-card-text>
                          <v-row justify="space-between">
                            <v-col cols="auto">
                              <span
                                :class="isSameSize(item.text) ? 'white--text' : ''"
                                style="text-transform: capitalize;"
                                >{{ $t(`theme.${item.text}`) }}</span
                              ></v-col
                            >
                            <v-col cols="auto"
                              ><v-icon :color="isSameSize(item.text) ? 'white' : ''">{{
                                item.icon
                              }}</v-icon></v-col
                            >
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5" :class="$vuetify.breakpoint.xs ? 'col-12' : ''">
                  <v-simple-table :dense="!isLarge" bordered>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Calories
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in demoData" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.calories }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>

    <!-- <v-col cols="12" class="pt-5 ">
      <div class="d-flex align-center">
        <VTextField
          dense
          outlined
          aria-autocomplete="off"
          v-model="user.sms_token"
          label="SMS Key"
          color="green"

          class="br-10"
          hide-details=""
          :disabled="!is_ts_token"
        />
        <v-switch color="success" hide-details v-model="is_ts_token"/>
      </div>
      <div class="listCounterparty" v-if="is_ts_token">

          <div class="listCounterparty__item" v-for="(item, index) in SMSlayoutList"
               :key="index">
            <span>
              {{ item.name }}
            </span>
            <div class="listCounterparty__item-buttons">
              <v-icon @click="onDeletSMS(item.uuid)">mdi-trash-can-outline</v-icon>
              <v-icon @click="onEditSMS(item)">mdi-pencil</v-icon>
            </div>
          </div>
          <div v-if="SMSlayoutList.length === 0" class="text-center my-4">
            У вас ще немає шаблонів
          </div>
          <div class="listCounterparty__item pt-2" >
            <VBtn @click="visibleLayout = true" class="success-bg text-white br-10 w-100">
              Додати
            </VBtn>
          </div>

      </div>

    </v-col> -->
    <v-card-actions :class="$vuetify.breakpoint.xs ? 'justify-center' : ''">
      <v-spacer v-if="!$vuetify.breakpoint.xs" />
      <v-col cols="auto" v-if="selectedMenu != 'interface'">
        <VBtn
          color="success"
          id="custom-disabled"
          @click="IsOpenPassForm"
          :disabled="!passwordChanged && !userChanged && !counterpartyChanged"
        >
          {{ $t("btn.save") }}
        </VBtn>
      </v-col>
    </v-card-actions>

    <ProfileDialogNP
      v-if="npDialog.visible"
      :visible="npDialog.visible"
      :typeDialog="npDialog.type"
      :data="npDialog.data"
      :phone="user.phone"
      :np_apikeys="np_apikeys"
      @close="closeProfileDialogNP"
    ></ProfileDialogNP>
    <ConfirmDeleteDialog
      :visible="deleteConfirm.visible"
      @close="closeDeleteCounterpartyDialog"
      @delete="onDelete"
    />
    <!-- <AddNewLayout :visible="visibleLayout" @close="visibleLayout = false"/> -->
  </v-card>
</template>

<script>
import _ from "lodash";
import { required, sameAs } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapGetters, mapActions } from "vuex";
import user from "../../mixins/user";
import * as actionTypes from "../../store/modules/auth/types/actions";
import phoneMask from "../../validators/phoneMask";
import usersService from "../../services/request/users/usersService";
import notifications from "../../mixins/notifications";
import loader from "../../mixins/loader";
import language from "../../mixins/language";
import ProfileDialogNP from "./ProfileDialogNP.vue";
import ConfirmDeleteDialog from "../dialog/ConfirmDeleteDialog.vue";
import novaPochtaService from "../../services/request/novaPochta/novaPochtaService";
import EventBus from "../../events/EventBus";
// import AddNewLayout from '../sms/AddNewLayout.vue';
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import passwordMask from "../../validators/passwordMask";
import {
  noEngText,
  onlyNumber,
  noEngTextPasted,
  validationForSurname,
  validationForName,
  validationForPhoneNumber,
  validationForPassword
} from "../../mixins/helpers";
import noEngTextValidator from "@/validators/noEngText";
import * as deportGetterTypes from "@/store/modules/moveList/types/getters";
import * as deportActionTypes from "@/store/modules/moveList/types/actions";
import ProfileAvatar from "./ProfileAvatar.vue";
import { MENU, getMenuText } from "./ProfileMenu.vue";
import theme from "@/mixins/theme";
import size from "@/mixins/SizeUi";
import CountryFlag from "vue-country-flag";
// const validPhone = (value) => value.replace(/[^+\d]/g, '').length === 13;

export default {
  mixins: [notifications, loader, validationMixin, user, language, theme, size],
  props: {
    user: {
      require: true
    },
    selectedMenu: {
      require: true
    },
    changePhotoPreview: {
      require: true
    },
    inputUser: {
      require: true
    },
    inputUserReset: {
      require: true
    },
    changedDis: {
      require: true
    }
  },
  components: {
    CountryFlag,
    ProfileDialogNP,
    ConfirmDeleteDialog,
    ProfileAvatar
  },
  validations: {
    user: {
      name: { required, noEngTextValidator },
      surname: { required, noEngTextValidator },
      phone: { required, phoneMask }
    },
    passForm: {
      newPass: { required, passwordMask },
      newPassRepeat: { required, passwordMask, sameAsPassword: sameAs("newPass") },
      currentPass: { required, passwordMask }
    }
  },
  data: () => ({
    resetPhoto:false,
    np_apikeys: [],
    menu: MENU,
    onEngText: true,
    SMSlayoutList: [],
    is_ts_token: false,
    visibleLayout: false,
    listCounterparty: [],
    listCounterpartyDefaultItem: 1,
    originalListCounterpartyDefaultItem: 1,
    npDialog: {
      visible: false,
      type: "add",
      data: {}
    },
    deleteConfirm: {
      visible: false,
      data: {}
    },
    loadingCounterparty: false,
    userChanged: false,
    passwordChanged: false,
    counterpartyChanged: false,
    showPass: false,
    currentPasswordVisible: false,
    newPasswordVisible: false,
    repeatPasswordVisible: false,
    fixedUser: {},
    themeCopy: null,
    passForm: {
      newPass: "",
      newPassRepeat: "",
      currentPass: ""
    },
    userPlanInfo: {},
    deport: {},
    demoData: [
      {
        name: "Frozen Yogurt",
        calories: 159
      },
      {
        name: "Ice cream sandwich",
        calories: 237
      }
    ]
  }),
  watch: {
    selectedMenu: {
      async handler(e) {
        if (this.permissions.client && this.np_apikeys.length === 0) {
          if(e === 'api_keys') {
            this.getListNPApiKeys();
          }
        }
      }
    },
    user: {
      deep: true,
      handler(user) {

        this.compareUserData(user);

        // // orderDialog('changed', this.changed);
      }
    },
    showPass: {
      deep: true,
      handler() {
        if (!this.showPass) {
          this.passwordChanged = false;
        }
      }
    },
    passForm: {
      deep: true,
      handler() {
        this.passwordChanged = !_.isEqual(this.passForm, {
          newPass: "",
          newPassRepeat: "",
          currentPass: ""
        });
        // // orderDialog('changed', this.changed);
        // // orderDialog('input', this.inputUserReset);
        // orderDialog(this.reset);
      }
    },
    listCounterpartyDefaultItem: {
      deep: true,
      handler() {
        this.counterpartyChanged = !_.isEqual(
          this.listCounterpartyDefaultItem,
          this.originalListCounterpartyDefaultItem
        );
        // // orderDialog('changed', this.changed);
      }
    }
  },
  computed: {
    ...mapGetters("moveList", {
      deports: deportGetterTypes.GET_DEPORTS
    }),
    ...mapGetters(["languages", "currentLanguage"]),
    passwordErrors() {
      const errors = [];
      if (!this.$v.passForm.currentPass.$dirty) {
        return errors;
      }
      if (!this.$v.passForm.currentPass.passwordMask) {
        errors.push(this.$t("form.errors.PasswordMustBeLike"));

        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.passForm.currentPass.required &&
        errors.push(this.$t("form.errors.CurrentPassRequired"));
      return errors;
    },
    newPassErrors() {
      const errors = [];
      if (!this.$v.passForm.newPass.$dirty) {
        return errors;
      }
      if (!this.$v.passForm.newPass.passwordMask) {
        errors.push(this.$t("form.errors.PasswordMustBeLike"));

        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.passForm.newPass.required && errors.push(this.$t("form.errors.NewPassRequired"));
      return errors;
    },
    newPassRepeatErrors() {
      const errors = [];
      if (!this.$v.passForm.newPassRepeat.$dirty) {
        return errors;
      }
      if (!this.$v.passForm.newPassRepeat.required) {
        errors.push(this.$t("form.errors.ConfirmPassRequired"));

        return errors;
      }
      if (!this.$v.passForm.newPassRepeat.passwordMask) {
        errors.push(this.$t("form.errors.PasswordMustBeLike"));

        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.passForm.newPassRepeat.sameAsPassword &&
        errors.push(this.$t("form.errors.PasswordsDontMatch"));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.user.name.$dirty) {
        return errors;
      }
      if (!this.$v.user.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.user.surname.$dirty) {
        return errors;
      }
      if (!this.$v.user.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.surname.required && errors.push(this.$t("form.errors.SurnameRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.user.phone.$dirty) {
        return errors;
      }
      if (!this.$v.user.phone.required) {
        errors.push(this.$t("form.errors.PhoneNumberRequired"));
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.phone.phoneMask && errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    },
    emailErrors() {
      return [];
    }
  },
  async mounted() {
    EventBus.$on("reload-list-counterparty", () => {
      this.counterpartyList();
    });
    EventBus.$on("on-change-image", user => {
      this.compareUserData(user);
    });
    if(!this.permissions.client) {
      this.counterpartyList();
    }
    if (this.permissions.client) {
      await this.fetchDeports();
      this.getPaymentPlan();
      this.getDeport();
    }
  },
  methods: {
    validationForPhoneNumber,
    validationForName,
    validationForSurname,
    getMenuText,
    noEngTextPasted,
    noEngText,
    onlyNumber,
    validationForPassword,
    ...mapActions("moveList", {
      fetchDeports: deportActionTypes.FETCH_DEPORTS
    }),
    compareUserData(user) {
      this.fixedUser = { ...user };
      this.fixedUser.phone = this.fixedUser.phone.replace(/[^+\d]/g, "");


      this.userChanged = !_.isEqual(this.fixedUser, this.inputUser);
    },
    onChangeSelectedThemeHandler(themeName) {
      const chosenTheme = this.getChosenTheme;
      if (chosenTheme == themeName) return;
      this.onChangeSelectedTheme(themeName);
    },
    isSameSize(sizeName) {
      return (
        (sizeName === this.SIZE_NAMES.LARGE && this.isLarge) ||
        (sizeName === this.SIZE_NAMES.DEFAULT && !this.isLarge)
      );
    },
    onChangeSelecteSize({ text: sizeName }) {
      if (this.isSameSize(sizeName)) return;
      this.setSize(sizeName === this.SIZE_NAMES.LARGE);
    },
    async switchLanguage(item) {
      if (this.currentLanguage.translationKey !== item.translationKey) {
        this.$setLanguage(item.translationKey);
        const currentLang = (this.currentLanguage.lang == 'en_EN' ? 'en_US' : 'uk_UA')
        await usersService.changeAccountLanguage(currentLang);
      }
    },
    async getPaymentPlan() {
      try {
        if (this.getUser.uuid_user_plan) {
          const planItem = await paymentPlansService.usersPlansInfo(this.getUserProperty('uuid_user_plan'));
          if (!planItem) {
            const notSetPlan = {
              name: this.$t("form.not_selected"),
              uuid: "00000000-0000-0000-0000-000000000000"
            };
            this.userPlanInfo = notSetPlan;
          } else {
            this.userPlanInfo = planItem;
          }
        }
      } catch (e) {
        this.setErrorNotification(this.$t("paymentPlan.withoutPaymentPlan"));
      }
    },
    ...mapActions("auth", {
      updateUserData: actionTypes.USER_UPDATE_DATA,
      logIn: actionTypes.LOG_IN
    }),
    getDeport() {
      this.deport = this.deports.find(dep => {
        return dep.uuid === this.user.uuid_deport;
      });
    },
    async changeRadioCounterparty(uuid) {
      try {
        this.loadingCounterparty = true;
        await novaPochtaService.setDefaultCounterparty(uuid);
        this.counterpartyChanged = false;
        this.setSuccessNotification(this.$t("profile.set_default_counterparty"));
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingCounterparty = false;
      }
    },
    IsOpenPassForm() {
      if (this.selectedMenu !== "password") {
        this.onUpdate();
      } else {
        this.ChangePass();
      }
    },
    async copyToCLipboard() {
      try {
        await navigator.clipboard.writeText(this.user.uuid_crm_apikey);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
    async ChangePass() {
      this.$v.$touch();
      if (!this.$v.passForm.$invalid) {
        try {
          // orderDialog('this.showPass', this.showPass);
          const formDataPass = new FormData();
          formDataPass.append("email", this.user.email);
          formDataPass.append("password_new", this.passForm.newPass);
          formDataPass.append("password_current", this.passForm.currentPass);
          const response = await usersService.userPasswordChange(formDataPass);
          // orderDialog('pass-respons', response);

          this.passForm = {
            newPass: "",
            newPassRepeat: "",
            currentPass: ""
          };
          this.$v.$reset();
          this.setSuccessNotification(this.$t("profile.password_changed_successfully"));
        } catch (e) {
          this.setErrorNotification(this.$t(`auth.errors.${e.error.description}`));
        }
      }
    },
    async onUpdate() {
      this.$v.$touch();

      if (!this.$v.user.$invalid && !this.showPass) {
        try {
          this.setLoading(true);
          const formData = new FormData();
          // orderDialog(this.getUser);
          formData.append("uuid", this.getUserProperty('uuid') || "");
          const changedData = this.$getChangedData(this.user, this.inputUser);

          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });
          // formData.append('name', this.user.name || '');
          // formData.append('surname', this.user.surname || '');
          // formData.append('phone', `+38${this.user.phone}`);
          // formData.append('company', this.user.company || '');
          if (this.user.photo) {
            formData.append("image", this.user.photo);
          }
          // if (this.user.np_apikey) {
          //   formData.append('np_apikey', this.user.np_apikey);
          // }
          // if (this.user.np_apikey) {
          //   formData.append('np_apikey', this.user.np_apikey);
          // }
          // formData.append('is_turbosms_active', this.user.is_turbosms_active ? '1' : '0');
          // if (this.user.is_turbosms_active) {
          //   formData.append('turbosms_apikey', this.user.turbosms_apikey);
          //   formData.append('turbosms_sender', this.user.turbosms_sender);
          // }
          // formData.append('is_np_apikey', this.user.is_np_apikey ? '1' : '0');
           const user = await this.updateUserData(formData);
          this.userChanged = false;
          this.passwordChanged = false;
          this.counterpartyChanged = false;
          this.setSuccessNotification(this.$t("profile.successfully_updated"));
          this.$v.$reset();
          this.setLoading(false);
          this.$emit("changeInputUser", true);
          EventBus.$emit("on-update");
          this.resetPhoto = !this.resetPhoto
          // this.$emit("photoPreview", true);
          // orderDialog(this.user.path_img, this.inputUser.path_img);
        } catch (e) {
          this.setLoading(false);
          console.log(e)
        }
      }
    },
    onDelete() {
      this.delayCounterparty(this.deleteConfirm.data.uuid);
      this.closeDeleteCounterpartyDialog();
    },
    closeDeleteCounterpartyDialog() {
      this.deleteConfirm = {
        visible: false,
        data: {}
      };
    },
    deleteCounterparty(item) {
      this.deleteConfirm = {
        visible: true,
        data: item
      };
    },
    closeProfileDialogNP() {
      this.npDialog = {
        visible: false,
        type: "add",
        data: {}
      };
    },
    editCounterparty(item) {
      this.npDialog = {
        visible: true,
        type: "edit",
        data: item
      };
    },
    async delayCounterparty(uuid) {
      try {
        await novaPochtaService.daleyCounterpartyItem(uuid);
        this.setSuccessNotification("Успішно видалено");
        this.counterpartyList();
      } catch (e) {
        console.error(e);
      }
    },
    async getListNPApiKeys(onReload = false, npApikey = "") {
      try {
        const params = {
          groupby: "np_apikey"
        };
        const response = await novaPochtaService.getCounterpartyList(params);

        this.np_apikeys = response.filter(el => el.np_apikey).map(el => el.np_apikey);

        if (onReload && this.user.np_apikey) {
          this.user.np_apikey = npApikey;
        }
        this.counterpartyList();
      } catch (e) {
        console.error(e);
      }
    },
    async counterpartyList() {
      this.listCounterpartyDefaultItem = -1;
      this.originalListCounterpartyDefaultItem = -1;
      try {
        const params = {};
        if (this.user.np_apikey) {
          params.np_apikey = this.user.np_apikey;
        } else {
          params.np_apikey = this.getUserProperty('np_apikey');
        }
        const response = await novaPochtaService.getCounterpartyList(params);
        // // orderDialog('counterpartyList', response);
        this.listCounterparty = response;
        for (let i = 0; i < response.length; i++) {
          if (Number(response[i].is_default) === 1) {
            this.listCounterpartyDefaultItem = i;
            this.originalListCounterpartyDefaultItem = i;
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.listCounterparty {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  margin: 15px 0 0;

  &__item {
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.38);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-buttons {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &:last-child {
      border: 0;
    }
  }
}

.params-frame {
  border-radius: 10px;
  border: 1px solid #dcdcdc;
}

.icon-btn {
  pointer-events: auto;
}

#custom-disabled.v-btn--disabled {
  opacity: 0.5;
  background: #4fae3b !important;
  color: #ffff !important;
}

.txt {
  span,
  b {
    font-weight: normal;
    font-size: 16px;
    color: #676767;
  }

  b {
    font-weight: 500;
    color: #3d3d3d;
  }
}

.change-pass {
  border-radius: 10px;
  padding: 14px 56px;
  border: 1px solid #dcdcdc;
  position: relative;

  span {
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;

    pointer-events: none;
  }

  &.sm {
    padding: 14px 16px;
  }
}

.btn-addCounterparties {
  min-width: 42px !important;
  padding: 0 !important;
}
</style>
