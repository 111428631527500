<template>
  <div>
    <VRow class="align-center d-flex justify-content-center">
      <VCol cols="12" class="text-center">
        <h3 class="text-h4">{{ $t("profile.settings") }}</h3>
      </VCol>
    </VRow>
    <VDivider class="my-3 mb-5" />
    <VRow class=" mt-5 pb-0">
      <VCol cols="3">
        <ProfileMenu />
      </VCol>
      <VCol cols="8">
        <!-- <ProfileAvatar :user="user" :changePhotoPreview="photoPreview" @changed="changed = true"/> -->
        <ProfileForm
          :selectedMenu="selectedMenu"
          :changePhotoPreview="photoPreview"
          :user="user"
          :inputUser="inputUser"
          :userInfo="userInfo"
          @changeInputUser="changeInputUser"
          @photoPreview="photoPreview = true"
          :changedDis="changed"
          :inputUserReset="inputUserReset"
        />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";

import user from "../../mixins/user";
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import loader from "../../mixins/loader";
import usersService from "../../services/request/users/usersService";
import ProfileAvatar from "./ProfileAvatar.vue";
import ProfileForm from "./ProfileForm.vue";
import ProfileMenu from "./ProfileMenu.vue";
import EventBus from "../../events/EventBus";

export default {
  name: "ProfileComponent",
  mixins: [language, notifications, loader, notifications, user, validationMixin],
  components: {
    ProfileAvatar,
    ProfileForm,
    ProfileMenu
  },
  data: () => ({
    user: {},
    inputUser: {},
    inputUserReset: {},
    photoPreview: false,
    changed: false,
    userInfo: {},
    selectedMenu: null
  }),

  async mounted() {
    EventBus.$on("on-change-selected-menu", selectedMenu => {
      this.selectedMenu = selectedMenu;
    });
    EventBus.$on("on-update", selectedMenu => {
      this.userInfo = {}
      this.changed = false
      this.getUserInfo()
    });
    this.setLoading(true);
    this.getUserInfo();
    this.setLoading(false);
  },
  methods: {
    getUserInfo() {
      const userInfo = this.getUser;
      userInfo.phone = userInfo.phone.replace("+38", "");
      if (userInfo) {
        this.user = {
          ...userInfo
        };

        this.inputUser = {
          ...this.user
        };
        this.inputUserReset = {
          ...this.reset
        };
      }
    },
    getFile() {
      const fileUpload = document.getElementById("customer_photo");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    changeInputUser(e) {
      if (e) {
        this.inputUser = {
          ...this.user
        };
      }
    },

    makePreview() {
      let photoPreview = null;
      const { photo } = this.user;
      const reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onloadend = ev => {
        photoPreview = ev.target.result;
        this.photoPreview = photoPreview;
      };
    }
  }
};
</script>

<style scoped lang="scss"></style>
